$black: #000;
$white: #fff;
$service-bg: #061024;
$waterLeaf: #9ce9de;
$fuchsiaPink: #cd5cb6;
$jaffa: #f57c3b;
$brandRed: #d5133b;

@import "../node_modules/plyr/src/sass/plyr.scss";
@import "../node_modules/bootstrap/scss/bootstrap";
// @import "../node_modules/bootstrap/scss/functions";
// @import "../node_modules/bootstrap/scss/variables";
// @import "../node_modules/bootstrap/scss/mixins";

// @import "../node_modules/bootstrap/scss/root";
// @import "../node_modules/bootstrap/scss/reboot";
// @import "../node_modules/bootstrap/scss/type";
// @import "../node_modules/bootstrap/scss/images";
// @import "../node_modules/bootstrap/scss/containers";
// @import "../node_modules/bootstrap/scss/grid";
// @import "../node_modules/bootstrap/scss/buttons";
// @import "../node_modules/bootstrap/scss/nav";
// @import "../node_modules/bootstrap/scss/navbar";
