@import "./mixins.scss";

:root {
  --plyr-color-main: #d5133b;
}

#app {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
}
a {
  color: inherit;
  text-decoration: none;
}
.color-dark {
  color: $black !important;
}
.scroll-disabled {
  overflow: hidden !important;
  height: 100vh;
  max-height: calc(var(--vh, 1vh) * 100);
}
.header {
  background: $black;
  color: $white;
  position: absolute;
  padding: 15px;
  top: 0;
  left: 0;
  color: $white;
  z-index: 2;
  max-height: 100vh;
  max-height: calc(var(--vh, 1vh) * 100);
  .logo {
    width: 165px;
    height: auto;
  }
  a {
    &:hover {
      color: inherit;
    }
  }
  .mobile-info {
    font-size: 14px;
    margin-bottom: 10px;
    a {
      margin-left: 7px;
    }
  }
  .menu-icon {
    width: 30px;
    height: auto;
  }
  .mobile-menu {
    color: $black;
    height: 0px;
    background: $black;
    overflow: hidden;
    transition: all 1.2s cubic-bezier(0.075, 0.82, 0.165, 1);
    border-top: 1px solid lighten($black, 20%);
    opacity: 0;

    a {
      display: block;
      padding: 10px 0px;
      border-bottom: 1px solid lighten($black, 20%);
    }
  }
  .mobile-menu.active {
    height: 100vh;
    height: calc((var(--vh, 1vh) * 100) - 97px);
    color: $white;
    opacity: 1;
  }
}
.intro {
  color: $white;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  background: $black;
  position: relative;
  a {
    color: inherit !important;
    &:hover {
      color: inherit !important;
    }
  }
  .main-img {
    transition: all 1ms ease;
    width: 100%;
    height: auto;
    position: absolute;
    bottom: 50px;
    z-index: -1;
  }
  .company-info {
    position: absolute;
    display: flex;
    width: 100%;
    bottom: 10px;
    padding: 15px;
    color: $white;
    .social-media {
      a {
        margin-right: 10px;
        i {
          transition: all 0.3s ease;
        }
      }
    }
  }
}
.tt {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background: $white;
}

.navbar-menu {
  a {
    font-family: "Poppins", "Helvetica", sans-serif;
    font-weight: 700;
    color: #000;
    text-transform: uppercase;

    &:hover {
      color: #1b4b36;
    }
  }
}

.navbar-menu {
  display: block;
  visibility: hidden;
  width: 100vw;
  height: 0;
  position: absolute;
}

.navbar-end {
  opacity: 0;
  padding-top: 20px;

  a {
    font-size: 1rem;
    font-weight: 400;
    text-transform: capitalize;
    color: #f0f0f0;
  }
}

.collapse-in .navbar-end {
  animation: show-menu-link 1s ease 0.5s forwards;
}
.ref-slider {
  padding: 15px 0px;
  color: $white;
  .title {
    color: $white !important;
  }
}
.mobile-app {
  margin: 20px auto;
  .hero-wrapper {
    overflow: hidden;
  }
  .hero {
    width: 160%;
    height: auto;
    margin-left: -30%;
    display: block;
  }
}
@keyframes show-menu-link {
  100% {
    opacity: 1;
  }
}

.menu-burger {
  background: transparent;
  padding: 0;
  display: inline-block;
  height: 43px;
  margin-top: -5px;
  margin-right: 2px;
  &:hover,
  &:active,
  &:focus,
  &:visited {
    background: transparent;
  }

  span {
    width: 38px;
    height: 4px;
    border-radius: 3px;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s;

    background-color: $white;
    display: block;
    position: relative;
    margin-top: -4px;
    right: -3px;
    &:nth-child(1) {
      top: calc(50% - 10px);
    }

    &:nth-child(2) {
      top: calc(50%);
    }

    &:nth-child(3) {
      top: calc(50% + 10px);
    }
  }
}

.active {
  span {
    background-color: $white;
    right: -3px;
    &:nth-child(1) {
      transform: rotate(45deg);
      top: 50%;
      width: 38px;
      height: 4px;
    }

    &:nth-child(2) {
      opacity: 0;
    }

    &:nth-child(3) {
      transform: rotate(-45deg);

      top: 50%;
      width: 38px;
      height: 4px;
    }
  }
}
.welcome-text {
  margin: 80px auto;
  .h5 {
    font-size: 26px;
  }
  .text {
    font-size: 17px;
  }
}
.service {
  background: url("../assets/images/back-light.png") no-repeat;
  background-size: contain;
  background-position: center top;
  background-color: $service-bg;
  border-radius: 30px;
  padding: 25px;
  color: $white;
  min-height: 200px;
  .title {
    background: -webkit-linear-gradient(to right, $waterLeaf 25%, #6eb8fd 45%);
    background: -moz-linear-gradient(to right, $waterLeaf 25%, #6eb8fd 45%);
    background: linear-gradient(to right, $waterLeaf 25%, #6eb8fd 45%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
    font-size: 47px;
  }
  .title-2 {
    font-size: 17px;
  }
  .hero-img {
    width: auto;
    height: 270px;
    margin: 0 auto 25px auto;
    display: block;
  }
  .text {
    font-size: 14px;
    line-height: 25px;
    color: darken($white, 5%);
  }
}

.site {
  .title {
    background: -webkit-linear-gradient(to right, #cd5cb6 25%, #5287ef 45%);
    background: -moz-linear-gradient(to right, #cd5cb6 25%, #5287ef 45%);
    background: linear-gradient(to right, #cd5cb6 25%, #5287ef 45%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
.mesh {
  .title {
    background: -webkit-linear-gradient(to right, #f57c3b 25%, #aa2d42 45%);
    background: -moz-linear-gradient(to right, #f57c3b 25%, #aa2d42 45%);
    background: linear-gradient(to right, #f57c3b 25%, #aa2d42 45%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
.btn-offer {
  padding-left: 40px;
  padding-right: 40px;
  border: 1px solid $waterLeaf;
  border-radius: 7px;
  color: $white;
  font-size: 12px;
  &:hover {
    background: $waterLeaf;
    border: 1px solid $waterLeaf;
    color: $white !important;
  }
}
.b-color-1 {
  border-color: $fuchsiaPink !important;
  &:hover {
    background: $fuchsiaPink;
    border: 1px solid $fuchsiaPink;
    color: $white;
  }
}
.b-color-2 {
  border-color: $jaffa !important;
  &:hover {
    background: $jaffa;
    border: 1px solid $jaffa;
    color: $white;
  }
}
.video {
  background: url("../assets/images/video-bg.jpg") no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 30px;
  display: flex;
  min-height: 250px;
  color: $white;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  .wrapper {
    margin-top: 70px;
  }
  .text {
    margin-top: 50px;
    display: block;
  }
}
.spacer {
  width: 100%;
  background: darken($white, 4%);
  height: 220px;
  z-index: -1;
  position: relative;
  margin-top: -170px;
}
.video-play-button {
  position: absolute;
  z-index: 10;
  transform: translateX(-50%) translateY(-50%);
  box-sizing: content-box;
  display: block;
  left: 50%;
  border-radius: 50%;
  opacity: 0.9;
}

.video-play-button:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 50px;
  height: 50px;
  background: $white;
  border-radius: 50%;
  animation: pulse-border 1500ms ease-out infinite;
}

.video-play-button:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 50px;
  height: 50px;
  background: $white;
  border-radius: 50%;
  transition: all 200ms;
}

.video-play-button:hover:after {
  background-color: darken($white, 10%);
}

.video-play-button img {
  position: relative;
  z-index: 3;
  max-width: 100%;
  width: auto;
  height: auto;
}

.video-play-button span {
  display: block;
  position: relative;
  z-index: 3;
  width: 0;
  height: 0;
  border-left: 16px solid #aa2d42;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  margin-left: 2px;
}

@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}
.no-radius {
  border-radius: 0;
}
.call-to-action-1 {
  background: $service-bg;
  min-height: 500px;
  color: $white;
  padding: 15px;
  #ref-carousel {
    height: 650px;
    position: relative;
  }
  .carousel-item {
    position: relative;
  }

  p {
    line-height: 24px;
  }
  .btn-offer {
    position: relative;
    left: 0;
    margin: 0;
  }
  .title {
    font-size: 21px;
  }
  .sub-title {
    font-size: 16px;
    margin-bottom: 5px;
  }
  .media {
    display: block;
    margin-top: 15px;
    margin-bottom: 15px;
    border-radius: 30px;
    width: 100%;
    height: 220px;
    object-fit: cover;
    object-position: center;
  }
  .quote {
    line-height: 24px;
    color: darken($white, 10%);
  }
  q {
    padding-left: 10px;
    border-left: 1px solid $white;
  }
  .carousel-indicators {
    bottom: -30px;
    left: 10px !important;
    margin-left: 0 !important;
    position: absolute;
    align-items: flex-start;
    justify-content: start;
    button {
      transition: all 0.3s ease-in-out;
    }
    .active {
      width: 40px;
    }
  }
}
.footer {
  min-height: 220px;
  background: darken($white, 4%);
  color: lighten($black, 20%);
  line-height: 20px;
  padding-bottom: 20px;

  p {
    font-size: 13px;
    font-weight: lighter;
  }
  a {
    color: lighten($black, 20%);
  }
  .footer-top {
    padding: 40px 0px;
  }
  .footer-bottom {
    font-size: 12px;
    font-weight: lighter;
  }
  .f-logo {
    text-align: center;
  }
  .social {
    text-align: center;
    margin-top: 30px;
    a {
      font-size: 24px;
      margin: 10px;
    }
  }

  ul {
    margin: 0;
    padding: 0 0 10px;
    li {
      list-style: none;
      font-size: 13px;
      font-weight: lighter;
    }
  }
}
#videoModal {
  .modal-content {
    position: relative;
  }
  .btn-close {
    position: absolute;
    right: 0;
    top: -40px;
    width: 25px;
    height: auto;
    opacity: 1;
  }
  .modal-body {
    padding: 0;
  }
}
.modal-backdrop.show {
  opacity: 0.8;
  backdrop-filter: blur(10px);
}
.page {
  position: relative;
  margin: 150px auto 50px auto;
}
.post--list-item {
  margin-bottom: 15px;
  a {
    color: inherit !important;
    &:hover {
      color: inherit !important;
    }
  }
  p {
    // padding: 0px 5px;
  }
  .h5 {
    // padding: 0px 5px;
    margin-top: 15px;
  }
  img {
    width: 100%;
    height: 300px;
    border-radius: 30px;
  }
}
.text-justify {
  text-align: justify;
}
.pagination {
  li {
    margin-left: 10px;
    a {
      color: inherit;
      width: 35px;
      height: 35px;
      border: 1px solid darken($white, 20%);
      border-radius: 50% !important;
      text-align: center;
      list-style: 35px;
      &:hover {
        color: $fuchsiaPink;
      }
    }
  }
}
.ref-list {
  .list-item {
    background: darken($white, 4%);
    border-radius: 30px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 70%;
      height: 70px;
      object-fit: contain;
      object-position: center;
    }
  }
}
@include media-breakpoint-up(sm) {
  .header {
    padding: 35px 50px;
    .desktop-menu {
      margin-top: 13px;
      a {
        margin-left: 15px;
        font-size: 15px;
        text-transform: uppercase;
        font-weight: bold;
      }
      .lang-nav {
        margin-left: 25px;
        position: relative;
        a {
          font-weight: normal;
          margin: 0px 0px 0px 10px;
        }
        .active {
          font-weight: bolder !important;
        }
        &:before {
          content: " ";
          position: absolute;
          left: -10px;
          top: 4px;
          width: 1px;
          height: 14px;
          background: $white;
        }
      }
    }
    .logo {
      width: 230px;
    }
  }
  .intro {
    height: 100vh !important;
    .main-img {
      width: 80%;
      bottom: -30vh;
    }
    .company-info {
      padding: 35px 50px;
      font-size: 16px;
    }
    .tel {
      margin: 0px 15px;
      padding: 0px 15px;
      position: relative;
      float: right;

      &:after {
        content: " ";
        position: absolute;
        right: 0px;
        top: 5px;
        width: 1px;
        height: 14px;
        background: $white;
      }
    }
  }
  .service {
    position: relative;
    padding: 65px 25px 25px 75px;
    background-position: right top;
    .title {
      font-size: 71px;
    }
    .title-2 {
      font-size: 24px;
    }
    .text {
      margin-top: 20px;
      font-size: 17px;
      line-height: 28px;
    }
    .hero-img {
      height: 450px;
    }
    .btn-offer {
      position: absolute;
      left: 70px;
      bottom: 70px;
    }
  }
  .double-service {
    .service {
      padding: 40px 40px;
      .title {
        font-size: 34px;
      }
      .title-2 {
        font-size: 14px;
      }
      .text {
        font-size: 12px;
        line-height: 21px;
      }
      .hero-img {
        height: 240px;
        margin-bottom: 0;
      }
      .btn-offer {
        left: 40px;
        bottom: 25px;
      }
    }
  }
  .video {
    min-height: 540px;
  }
  .spacer {
    height: 330px;
  }
  .call-to-action-1 {
    background: $service-bg;
    padding: 90px 15px;
    height: 575px;
    min-height: 575px;
    .btn-offer {
      position: relative;
      left: 0;
      margin-top: 25px;
      bottom: unset;
    }
    .title {
      font-size: 32px;
    }
    .text {
      font-size: 14px;
    }
    #ref-carousel {
      height: auto;
      position: relative;
    }
    .carousel-inner {
      overflow: visible;
    }
    .media {
      width: calc(100% + 150px);
      max-width: 100vw !important;
      height: 700px;
      margin-left: -75px;
      position: absolute;
      top: -284px;
      z-index: 1;
    }
    p,
    .quote,
    .title {
      z-index: 3;
      position: relative;
    }
    .overlay {
      width: calc(100% + 150px);
      max-width: 100vw !important;
      height: 700px;
      margin-left: -75px;
      position: absolute;
      top: -270px;
      border-radius: 30px;
      background: rgb(0, 0, 0);
      background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0.8) 0%,
        rgba(0, 0, 0, 0) 100%
      );
      z-index: 2;
    }
    .section-title {
      position: absolute;
      margin-top: -80px;
      z-index: 3;
    }
    .sub-title {
      position: relative;
      z-index: 2;
      margin-top: 40px;
    }
    .carousel-item {
      height: 380px;
    }
    .quote {
      margin-top: 20px !important;
    }
    .ref-slider {
      .title.fw-bolder {
        font-size: 21px;
        margin-top: 40px;
      }
    }
    q {
      font-style: italic;
    }
  }
  .mobile-app {
    margin: 160px auto 0px auto;
    .hero-wrapper {
      overflow: visible;
      .hero {
        width: 180%;
        margin-left: -35%;
      }
    }
    .pt-c {
      padding-top: 75px;
    }
  }
  .footer {
    .ps-sm-5 {
      padding-left: 6rem !important;
    }
    .footer-top {
      border-bottom: 1px solid darken($white, 20%);
    }
    .f-logo {
      text-align: left;
      .logo {
        width: 250px;
        height: auto;
      }
    }
    .social {
      text-align: right;
      margin-top: 10px;
      a {
        font-size: 24px;
        margin: 0px 0px 0px 20px;
      }
    }
    .footer-body {
      padding: 30px 0px 90px 0px;
      border-bottom: 1px solid darken($white, 20%);
      p {
        max-width: 80%;
      }
    }
    .footer-bottom {
      padding: 20px 0px 10px 0px;
    }
  }
  #videoModal {
    .modal-dialog {
      max-width: 1100px;
    }
  }
  .page {
    margin: 230px auto 100px auto;
    padding: 0;
    min-height: 300px;
  }
  .post--list-item {
    img {
      width: 100%;
      height: 220px;
      border-radius: 30px;
    }
  }
  .mailto {
    float: right;
  }
  .grayscaled {
    .list-item {
      filter: grayscale(100%);
      &:hover {
        filter: grayscale(0);
      }
    }
  }
  .ref-list {
    .list-item {
      height: 200px;
      transition: all 0.35s ease;
      img {
        width: 52%;
        height: 70px;
        object-fit: contain;
        object-position: center;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .container {
    max-width: 1100px;
  }
}
